import "./Header.css";
import Navigation from "./Navigation";

function Header() {
  return (
    <div>
      <header className="header">
        <div className="center-container">
          <a href="/">
            <img src="logo.svg" alt="Rice Kitchen Come logo" className="logo" />
          </a>
        </div>
      </header>
      <Navigation />
    </div>
  );
}

export default Header;
