import Page from "../components/Page";
import "./Catering.css";
function ContactUs() {
  return (
    <Page>
      <div className="contact-us">
        <h1>Contact us</h1>
        <br />
        <p>Whether you need assistance, have a question about our products,</p>
        <p>
          or simply want to share your feedback, our team is ready to assist.
        </p>
        <br />
        <strong>
          📧 Contact us by email:{" "}
          <a href="mailto:info@comefood.dk">info@comefood.dk</a>
        </strong>
        <br />

        <p>
          We strive to respond as quickly as possible to ensure you have the
          best experience. Reach out anytime!
        </p>

        <span>Our store address: </span>
        <strong>Flæsketorvet 44, 1711 København</strong>

        <br />
        <p>Tuesday - Saturday 11:00-18:00</p>
        <p>Sunday closed</p>
        <p>Monday closed</p>
        <p>( holiday will be announced on Instagram @come.rice.kitchen )</p>
        <img src="/contactus.png" alt="event" />
      </div>
    </Page>
  );
}

export default ContactUs;
