import Page from "../components/Page";
import "./Catering.css";

function Catering() {
  return (
    <Page>
      <div className="contact-us">
        <h1>Bring Our Flavor to Your Next Event!</h1>
        <br />

        <p>
          Make your gathering truly unforgettable with our delicious catering
          services!{" "}
        </p>
        <p>
          We offer a variety of our Onigiri to meet the needs of any event –
          from intimate gatherings to large celebrations.{" "}
        </p>
        <p>
          Whether it’s a corporate function, a wedding, or a special occasion,{" "}
        </p>
        <p>let us handle the food so you can focus on making memories. </p>
        <br />
        <strong>
          📧 Contact us to discuss your catering needs:{" "}
          <a href="mailto:info@comefood.dk">info@comefood.dk</a>
        </strong>
        <br />

        <p>
          We’re excited to bring the taste of come rice kitchen straight to your
          table!
        </p>

        <img src="/event.png" alt="event" />
      </div>
    </Page>
  );
}

export default Catering;
