import React from "react";
import "./Home.css";
// import OnigiriCard from "../components/OnigiriCard";
// import OnigiriMenu from "../components/OnigiriMenu";
import AboutSection from "../components/AboutSection";
import Footer from "../components/Footer";
import Header from "../components/Header";
function Home() {
  return (
    <div>
      <Header />
      <AboutSection />
      <Footer />
      {/* <div className="full-width">
        <img src="/banner.png" alt="banner" className="banner" />
      </div> */}
      {/* <section className="process-section">
        <div className="process">
          <div className="step">
            <img src="/01.svg" alt="Washing Rice" />
          </div>
          <div className="step">
            <img src="/02.svg" alt="Icing" />
          </div>
          <div className="step">
            <img src="/03.svg" alt="Cooking Rice" />
          </div>
        </div>
      </section> */}
      {/* <section className="onigiri-display">
        <div className="onigiri-header">
          <h1>Onigiri</h1>
          <p>
            Onigiri (おにぎり), sometimes called Omusubi (おむすび),
            are Japanese rice balls. They are what I call the magical food of
            the Japanese. Tender, toothsome rice made portable, they are the
            classic comfort food for picnics (especially during the cherry
            blossom viewing), bento lunch boxes, quick grab-and-go snacks,
            hiking trips, movie snacks, etc.
          </p>
            <img src="onigiri.png" alt="making onigiri" />
        </div>
      </section> */}
      {/* <OnigiriMenu />
      <section className="tempura-list">
        <div className="vertical-text">{""}</div>
        {["Menchi Katsu", "Chicken Karaage"].map((item) => (
          <OnigiriCard key={item} name={item} />
        ))}
        <div className="vertical-text">天ぷら</div>
      </section>

      <section className="sashimi-list">
        <div className="vertical-text">おかず</div>
        <ul className="sashimi-list">
          {[
            "Daikon Salad 白萝卜沙拉",
            "Spicy Doraji Salad 拌桔梗 ",
            "Spicy Radish Salad  辣萝卜",
            "Goma Wakama 海带沙拉 ",
            "Age Hitashi(Eggplant Salad) 茄子沙拉",
          ].map((menu) => (
            <li>{menu}</li>
          ))}
        </ul>
        <ul className="sashimi-list">
          {[
            "Salmon Sashimi",
            "Bluefin Tuna Sashimi: Akame, Chūtoro, Toro",
            "Japanese Hamachi Sashimi",
            "Scallop Sashimi",
            "Squid Sashimi",
          ].map((menu) => (
            <li>{menu}</li>
          ))}
        </ul>
      </section> */}
      {/* <section className="banner-footer-display">
        <div className="banner-footer">
          <img src="store.png" alt="food in store" />
        </div>
      </section> */}
    </div>
  );
}

export default Home;
