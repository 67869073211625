import React from 'react';
import StyledHeader from './StyledHeader';
import './AboutSection.css';
import GiftCard from "./GiftCard";
import Reviews from "./Reviews";

const AboutSection = () => {
  return (
    <section className="about-section">
      <div className="section-one">
        <div className="image-container">
          <img src="header-image.jpg" alt="Onigiri" className="header-image" />
        </div>
        <div className="text-container">
          <StyledHeader firstWord="Comé">
          Rice Kitchen originated in the summer of 2022, beginning as a fish market at Ume Asian Supermarket. <br /><br />The experience at Ume highlighted a strong interest in Japanese culture, particularly in <strong>Onigiri</strong> (Japanese rice balls). 
          Motivated by this demand, Comé Rice Kitchen was launched when the adjacent store space became available. It now specialises in offering authentic Japanese rice dishes, introducing their rich flavours and traditions to Copenhagen.
          </StyledHeader>
        </div>
      </div>
      <GiftCard />

      {/* 第二部分 */}
      <div className="section-two">
        
        <img src="chef-icon.svg" alt="Chef Icon" className="chef-icon" />
        <p className="chef-name">Chef Hiroo Machida 町田寛夫</p>
      </div>

      {/* 第三部分 */}
      <div className="section-three">
        <div className="text-container">
          <StyledHeader firstWord="Born">
          in a traditional Japanese family, his father owns a seafood company. 
          Hiroo Machida grew up immersed in a culture of excellence.From an early age, he developed a deep appreciation for high-quality ingredients and a passion for cooking.
          <br/>
          <br/>
          After graduating high school, Machida moved to Tokyo to pursue his career, studying French and Italian cuisine at Tokyo Culinary School. Upon graduation, he served as head chef at Michelin-starred restaurants <strong>Tateru Yoshino</strong> and <strong>Tortue Tokyo</strong> for several years.

          <br/>
          <br/>
          In 2015, a unique opportunity brought him to Denmark, where he embarked on a journey of self-discovery. Living abroad deepened his appreciation for traditional Japanese culture, inspiring him to share the essence of Japanese cuisine with Danish diners. Recognising rice as the cornerstone of Japanese culinary culture, he chose it as the foundation for introducing authentic Japanese flavours to his new audience.
          </StyledHeader>
        </div>
        <div className="image-container">
          <img src="chef-image.jpg" alt="Chef Cooking" className="chef-image" />
        </div>
      </div>


      <Reviews />

      <div className="section-two">
        
        <img src="onigini_icon.svg" alt="Onigi Icon" className="chef-icon" />
        <p className="chef-name">Rice culture and concept</p>
      </div>
      <div className="text-container">
      <StyledHeader firstWord="In">
        Asian traditions, rice represents more than just a main dish—it is a profound cultural symbol. It embodies the essence of life, connecting families and communities while reflecting the spirit of harvest and sharing. Across Asia, rice holds an indispensable place in daily meals, carrying memories and emotions. Whether it’s Japan’s sushi, Korea’s bibimbap, or China’s fried rice, rice serves as a central ingredient, showcasing diverse regional flavors and cooking traditions.
      </StyledHeader>
      </div>

      <img src="rice_image.jpg" alt="rice" />

      <div className="text-container">

      <StyledHeader firstWord="Our Mission">
        is to introduce the charm of rice through a variety of rice-based delicacies, with a focus on Onigiri (Japanese rice balls). By offering meticulously crafted Onigiri and other rice-based dishes, Comé Rice Kitchen aspires to ignite curiosity and passion for rice culture. Using high-quality rice and fresh ingredients, combined with traditional culinary techniques, we strive to create delicious dishes that highlight the essence of rice.
       </StyledHeader>
       </div>
      <div className="section-four">
        <div className="image-grid">
          <img src="shop1.jpg" alt="shop 1" className="grid-image" />
          <img src="shop2.jpg" alt="shop 2" className="grid-image" />
          <img src="shop3.jpg" alt="shop 3" className="grid-image" />
          <img src="shop4.jpg" alt="shop 4" className="grid-image" />
        </div>
      </div>

    </section>
  );
};

export default AboutSection;
