import "./UmeSupermarket.css";
const UmeSupermarket = ({ word }) => {
  const handleRedirect = () => {
    window.location.href = "https://umefood.dk";
  };
  return (
    <div className="ume">
      <p>All our {word} are available in UME Asian supermarket!</p>
      <div className="redirect-box" onClick={handleRedirect}>
        Go shop in umefood.dk
      </div>
    </div>
  );
};

export default UmeSupermarket;
