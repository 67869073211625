import Page from "../components/Page";

function Supermarket() {
  return (
    <Page>
      <div className="contact-us">
        <h1>Contact Us</h1>
        <p>
          We would love to hear from you! Please feel free to reach out to us
          with any questions, feedback, or just to say hello. We will do our
          best to respond to your message
        </p>
      </div>
    </Page>
  );
}

export default Supermarket;
