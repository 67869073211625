import Page from "../components/Page";
// import OnigiriMenu from "../components/OnigiriMenu";
// import NewYearCard from "../components/NewYearCard";
// import AboutSection from "../components/AboutSection";
import "./Home.css";
// import styles from "./Onigiri.css"; // 已不再使用

import "./Onigiri.css";
import UmeSupermarket from "../components/UmeSupermarket";

import StyledHeader from "../components/StyledHeader";
function Onigiri() {
  return (
    <Page>
      <div className="onigiri-container">
        <h1 className="first-word">What is Onigiri?</h1>

        <h2 className="styled-header">
          Onigiri, also known as Japanese rice balls, is a traditional Japanese
          food made by shaping rice into triangular, round, or other forms,
          often with various fillings like pickled vegetables, fish, meat, or
          seafood. It is widely enjoyed in Japanese households and is a staple
          in bento boxes and picnics.
        </h2>

        {/* 图片 */}
        <div>
          <img
            src="onigiri_long.png"
            alt="Onigiri arranged on a wooden board"
          />
        </div>

        {/* 第二段介绍 */}
        <br />
        <StyledHeader firstWord="Onigiri">
          , or Japanese rice balls, are a versatile and convenient food, perfect
          for breakfast, lunch, or a quick snack. Often included in bento boxes,
          they are a staple for busy individuals. Their portability also makes
          them a popular choice for outdoor activities and travel, offering a
          simple yet satisfying meal option.
        </StyledHeader>

        <div className="center-h2">
          <h2>Key Features of Onigiri</h2>
        </div>

        {/* 列表 */}
        <ul>
          <li>
            Base Ingredient: Freshly cooked Japanese rice, chosen for its
            stickiness and smooth texture.
          </li>
          <li>
            Shapes: Traditionally triangular, but round and molded shapes are
            also common.
          </li>
          <li>
            Fillings: Umeboshi, salmon, seaweed, kombu, shrimp, and pickled
            vegetables, among others, vary with seasons and tastes.
          </li>
          <li>
            Wrap: Often encased in nori (seaweed) for added flavor and
            portability.
          </li>
        </ul>

        <br />
        <h2 className="styled-header">
          Onigiri remains a symbol of simplicity and versatility, celebrated for
          its rich cultural roots and adaptability to modern tastes.
        </h2>

        {/* ========== Step 1（洗米 - 展示3张图） ========== */}
        <div className="process">
          <div className="step">
            <img src="/01.svg" alt="Washing Rice" />
          </div>
          <div className="step">
            <img src="/02.svg" alt="Icing" />
          </div>
          <div className="step">
            <img src="/03.svg" alt="Cooking Rice" />
          </div>
        </div>

        {/* ========== History of Onigiri ========== */}
        <div className="center-h2">
          <h2>History of Onigiri</h2>
        </div>
        {/* Ancient Origins */}
        <h4>Ancient Origins (c. 200 BCE – 800 CE)</h4>
        <ul>
          <li>
            Early forms of rice balls, known as Nasubi ("hand-pressed rice"),
            were used in rituals and celebrations for their convenience in
            carrying and sharing.
          </li>
        </ul>
        <br />

        {/* Heian Period */}
        <h4>Heian Period (794 – 1185)</h4>
        <ul>
          <li>
            Onigiri evolved with added flavors and fillings, like umeboshi
            (pickled plum). It became a refined dish enjoyed not only as daily
            food but also at aristocratic banquets.
          </li>
        </ul>
        <br />

        {/* Warring States and Edo Periods */}
        <h4>Warring States and Edo Periods (1467 – 1868)</h4>
        <ul>
          <li>
            During the Warring States period, Onigiri became a staple for
            soldiers due to its portability. By the Edo period, Onigiri was
            widely embraced as part of daily meals, with diverse flavors and
            shapes.
          </li>
        </ul>
        <br />
        {/* Meiji Era to Modern Times */}
        <h4>Meiji Era to Modern Times (1868 – Present)</h4>
        <ul>
          <li>
            With urbanization, Onigiri production shifted to restaurants and
            bento shops, increasing its accessibility. Today, Onigiri enjoys
            global popularity with modern variations, such as unique fillings,
            seaweed wraps, and innovative shapes.
          </li>
        </ul>
        <br />

        {/* Cultural Significance */}
        <h4>Cultural Significance</h4>
        <ul>
          <li>
            Onigiri symbolizes family traditions and communal joy. It is often
            handmade during celebrations, offering shared experiences of
            creating and enjoying this simple yet meaningful dish.
          </li>
        </ul>

        <div>
          <img src="rice.jpg" alt="rice" />
        </div>

        <div className="center-h2">
          <h2>Our Rice</h2>
        </div>
        <h2 className="styled-header">
          In Japan, rice is not just a main dish but a cultural cornerstone,
          cultivated in diverse varieties influenced by regional climates and
          conditions. Each variety offers distinct textures, flavours, and
          appearances. At Comé Rice Kitchen, Chef Hiroo ensures only the finest
          rice is selected to meet our quality standards. The varieties we
          currently use include:
        </h2>

        <h4>Haenuki (はえぬき)</h4>
        <ul>
          <li>
            Characteristics: Haenuki is a medium-grain short rice renowned for
            its stickiness and texture.
          </li>
          <li>
            When cooked, the grains are smooth, soft, and aromatic, with a
            pleasant chewiness. Its subtle sweetness pairs well with a variety
            of dishes.{" "}
          </li>
        </ul>

        {/* Heian Period */}
        <h4>Hitomebore (ひとめぼれ)</h4>
        <ul>
          <li>
            Characteristics: Hitomebore is a short-grain rice loved for its soft
            texture and balanced stickiness.
          </li>
          <li>
            Once cooked, it exudes a delightful fragrance and maintains its
            flavor and texture even after cooling, making it versatile for many
            dishes.{" "}
          </li>
        </ul>

        {/* Warring States and Edo Periods */}
        <h4>Ubara Rice (うばら米)</h4>
        <ul>
          <li>
            Characteristics: Ubara is a premium Japanese rice with plump, smooth
            grains.
          </li>
          <li>
            When cooked, it delivers a soft, slightly sticky texture and a
            naturally fragrant aroma, making it ideal for sushi, rice balls, or
            other Japanese cuisine.{" "}
          </li>
        </ul>
        <h2 className="styled-header">
          At Comé Rice Kitchen, these premium varieties are chosen to highlight
          the richness and versatility of Japanese rice, ensuring an authentic
          and flavourful dining experience.
        </h2>

        <UmeSupermarket word="rice" />
      </div>
    </Page>
  );
}

export default Onigiri;
