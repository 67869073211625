import Page from "../components/Page";
function Openinghours() {
  return (
    <Page>
      <div className="contact-us">
        <h1>Opening hours</h1>
        <br />
        <p>Tuesday - Saturday 11:00-18:00</p>
        <p>Sunday closed</p>
        <p>Monday closed</p>
        <p>( holiday will be announced on Instagram @come.rice.kitchen )</p>
        <img src="/openhour.png" alt="event" />
      </div>
    </Page>
  );
}

export default Openinghours;
