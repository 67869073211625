import { NavLink } from "react-router-dom";
import "./Navigation.css";

function Navigation() {
  return (
    <nav className="navigation">
      <NavLink to="/onigiri" activeClassName="active">
        Onigiri
      </NavLink>
      <NavLink to="/side-dish" activeClassName="active">
        Side dish
      </NavLink>
      <NavLink to="/sake" activeClassName="active">
        Sake
      </NavLink>
      <NavLink to="/catering" activeClassName="active">
        Catering
      </NavLink>
    </nav>
  );
}

export default Navigation;
