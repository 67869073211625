import React from 'react';
import './StyledHeader.css';

const StyledHeader = ({ firstWord, children }) => {
  return (
    <h2 className="styled-header">
      <span className="first-word">{firstWord}</span> {children}
    </h2>
  );
};

export default StyledHeader;
