import React from "react";
import "./Reviews.css";

const Reviews = () => {
  return (
    <div className="reviews-container">
      <div className="reviews-row">
        <a
          href="https://www.berlingske.dk/gastronomi/de-her-japanske-haandmadder-sender-leverpostejen-til-taelling"
          target="_self"
        >
          <img src="review1.svg" alt="review 1" />
        </a>
        <a
          href="https://migogkbh.dk/er-det-koeen-vaerd-migogkbh-har-testet-nyt-og-mega-hypet-japansk-koncept/"
          target="_self"
        >
          <img src="review2.svg" alt="review 2" />
        </a>
      </div>
      <div className="reviews-center">
        <a
          href="https://politiken.dk/ibyen/anmeldelser/cafeanmeldelser/art10103834/Jeg-spiser-gerne-min-frokost-siddende-p%C3%A5-kantstenen-i-b%C3%A5de-regn-og-slud-n%C3%A5r-kvaliteten-er-som-her?srsltid=AfmBOoo1kjTPbs2aCjy3oxOwH3s0lFhedQf3C5oyz4fj28OjMSRxDTgR"
          target="_self"
        >
          <img src="review3.svg" alt="review 3" />
        </a>
      </div>
    </div>
  );
};

export default Reviews;
