import React from "react";
import "./GiftCard.css";

const GiftCard = () => {
  const handleButtonClick = () => {
    window.location.href = "https://umefood.dk/product/come-gift-card/";
  };
  return (
    <div className="gift-card">
      {/* 左侧部分 */}
      <div className="gift-card-left">
        <h2>🎁 Comé Gift card</h2>
      </div>
      {/* 中间部分 */}
      <div className="gift-card-center">
        <p>Looking for a surprise for a friend or loved one?</p>
        <a
          href="https://umefood.dk/product/come-gift-card/"
          className="gift-card-link"
        >
          Order a Gift Card here!
        </a>
      </div>
      {/* 右侧部分 */}
      <div className="gift-card-right">
        <img
          src="giftcard.png"
          onClick={handleButtonClick}
          alt="Gift Card"
          style={{ cursor: "pointer" }}
        />
      </div>
    </div>
  );
};

export default GiftCard;
