import React from "react";
import "./SideDish.css";
import Page from "../components/Page";
import StyledHeader from "../components/StyledHeader";

const SideDish = () => {
  const menu = {
    salads: [
      { name: "Daikon Salad", description: "白萝卜沙拉" },
      { name: "Spicy Doraji Salad", description: "辣桔梗" },
      { name: "Spicy Radish Salad", description: "辣萝卜" },
      { name: "Goma Wakame", description: "芝麻海带" },
      { name: "Age Hitashi (Eggplant Salad)", description: "煎茄子沙拉" },
      { name: "Tofu Salad", description: "豆腐沙拉" },
    ],
    sashimi: [
      { name: "Salmon", description: "" },
      { name: "Bluefin Tuna: Akame", description: "" },
      { name: "Chūtoro", description: "" },
      { name: "Toro", description: "" },
      { name: "Japanese Hamachi", description: "" },
      { name: "Scallop", description: "" },
      { name: "Squid", description: "" },
    ],
    friedSnacks: [
      { name: "Menchi Katsu", description: "肉饼炸肉" },
      { name: "Rice Croquette", description: "牛肉土豆炸饭" },
      { name: "Chicken Karaage", description: "炸鸡" },
      { name: "Tempura Shrimp", description: "虾天妇罗" },
    ],
  };

  return (
    <Page>
      <div className="menu-layout">
        <StyledHeader firstWord="Try">
          our delicious side dish. We offer fresh sashimi, fresh made salads and
          fried snacks everyday in store :)
        </StyledHeader>

        <div className="image-container">
          <img src="/sidedish.png" alt="Sashimi and salads" />
        </div>
        <div className="menu-container">
          <div className="menu-column">
            <h2>Salad</h2>
            <img src="/leaf.svg" alt="leaf icon" />
            <ul>
              {menu.salads.map((item, index) => (
                <li key={index}>
                  {item.name} {item.description}
                </li>
              ))}
              <img src="/fish.svg" alt="fish icon" />
              <li>Salmon Salad</li>
            </ul>
          </div>
          <div className="menu-column">
            <h2>Sashimi</h2>
            <img src="/fish.svg" alt="fish icon" />
            <ul>
              {menu.sashimi.map((item, index) => (
                <li key={index}>
                  {item.name} {item.description}
                </li>
              ))}
            </ul>
          </div>
          <div className="menu-column">
            <h2>Fried Snacks</h2>
            <img src="/meat.svg" alt="shrimp icon" />

            <ul>
              {menu.friedSnacks.map((item, index) => (
                <li key={index}>
                  {item.name} {item.description}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </Page>
  );
};

export default SideDish;
