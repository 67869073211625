import Page from "../components/Page";
import UmeSupermarket from "../components/UmeSupermarket";
import "./Sake.css";
function Sake() {
  return (
    <Page>
      <UmeSupermarket word="sake" />
      <div className="image-container">
        <img src="/sake_page.png" alt="sake" />
      </div>
    </Page>
  );
}

export default Sake;
